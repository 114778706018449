<template>
    <v-container>
        <v-col cols="12" class="mt-0 mt-md-16 mb-15">
            <h1>Terms and Conditions</h1>
            <p class="mt-10">Terms and Conditions of our platform</p>
            <b class="mt-16">Effective 12 July, 2024</b>

            <p class="mt-8">Harmony is a content sharing platform that enables creators to connect their audiences with their most recent and relevant content. One of our core values is to pursue deliberate simplicity — so we’ve tried to distill our terms and conditions into their simplest form here.</p>
            <p>It’s great to have you here. By using Harmony, you agree to these Terms & Conditions (“Terms”) and the other policies that we link to here and on the Platform.</p>
            <p>These Terms (and the policies we link to) apply to your use of our website (https://harmony.fan/) apps and any other software or features provided in connection with our services (collectively the “Platform” or “Harmony”). When we say “we”, “our” or “us” in these Terms, we’re referring to Bybio and our group companies.</p>

            <p>Please read these Terms carefully and reach out if you have any questions. If you don’t agree with them, please don’t use Harmony.</p>

            <p>Your account</p>

            <p>To create an account and become a Harmony user, you must be at least 18. If you’re creating an account on behalf of someone else, you must have their permission to do so. You’re responsible for your account and using it in a lawful way.</p>

            <p>When you create an account, you agree to comply with these Terms and that you’re (i) over 18, and (ii) legally able to enter these Terms with us. You must provide us with accurate info about yourself — if anything changes, please let us know so we can update your details.</p>

            <p>If you’re using Harmony on behalf of a business or individual, you agree that you’re authorised by them to act and agree to these Terms on their behalf.</p>

            <p>You’re responsible for anything that happens to your account, so keep your login details and password safe and don’t share them with anyone. If you think your account has been compromised, please contact us immediately.</p>

            <p>You must not assign or transfer your account to someone else, or use your account (or allow it to be used by anyone) in a way which in our reasonable opinion, causes damage to Harmony or our reputation, or infringes another’s rights or applicable laws and regulations.</p>

            <p>Your username</p>

            <p>The username you choose must be appropriate for everyone and cannot infringe someone else’s rights.</p>

            <p>We know the username you choose is very important to you. But it’s also important that your username is appropriate for all audiences and doesn’t infringe anyone’s rights, including intellectual property rights (such as copyright and trade marks) (“IP Rights”).</p>

            <p>You can’t use the name of another person (such as a celebrity), brand or company, have a username that is offensive, vulgar or obscene, or create an account with a username that you have no connection with just to profit from it in the future (known as “Domain Squatting”).</p>

            <p>If any of these issues occur over your username, we’ll consider the circumstances reasonably and may require you to change it (and we may reassign it to someone else). If you refuse, we may suspend or cancel your account. If someone claims that your username infringes their IP Rights, they’ll need to complete an Intellectual Property report and you’ll have the chance to then issue a Counter Notice.</p>

            <p>If you’ve not signed in or added new links or had any traffic to your account in the last 6 months we may reclaim or reassign your username (but never without contacting you first).</p>

            <p>Your content — what is and isn’t allowed</p>

            <p>We love the variety of content that our users post on Harmony! However, we want to ensure that everyone who visits the Platform can do so safely. That’s why we have our Community Standards.</p>

            <p>Our Community Standards set out what content is and isn’t allowed on Harmony, so please make sure you follow them, otherwise we may suspend or permanently remove your account.</p>

            <p>When we talk about your “content”, we mean the text, graphics, videos, links, and any other materials you add to your Harmony profile. You’re responsible for your content and you warrant that:</p>

            <p>it’s yours or if you’re using third party content, you have all of the rights needed to post it on Harmony (and to let us use it in accordance with these Terms);</p>

            <p>none of your content will violate the privacy, publicity, IP or other rights of anyone else;</p>

            <p>your content will not (i) be misleading or deceptive, intended or designed to misinform, or likely to misinform a reasonable person, (ii) cause either of us to break any laws or legal obligations, and (iii) bring us into disrepute or damage our reputation;</p>

            <p>your content doesn’t contain any viruses or other harmful code, files, or programs designed to interrupt or damage the functionality of the Platform or any other software, hardware or device;</p>

            <p>you will not use, or authorise others to use, automated scripts or other scraping tools to collect information from your profile, or the Platform;</p>

            <p>you won’t post any unauthorised advertising, solicitations or endorsements on Harmony; and</p>

            <p>all of your content complies with the Community Standards.</p>

            <p>Since laws and regulations differ from country to country, we may ban content that might be legal in some countries, and to take whatever action we think necessary, including removing content or restricting access to it or the Platform.</p>

            <p>Your content — what we can do with it</p>

            <p>We love your content and want to show it off. When you post content on Harmony, you give us the right to display, use, and share it.</p>

            <p>When you post content on your profile, you grant us a licence to (i) use, publicly display, distribute, modify, adapt and create derivative works of such content; and (ii) use your name, image, voice, photograph, likeness and any other personal attributes in the content; on the Platform and in our marketing in all media (such as our social channels and any other advertising). This licence is worldwide, royalty-free and perpetual, which means we can use your content anywhere in the world, without paying you fees, for as long as we like. You agree that you have all third party rights necessary to post the content on Harmony and to grant us this licence.</p>

            <p>You’ll retain all of your rights in your content. But keep in mind that your content will be publicly accessible, and may be used and re-shared by others on Harmony and across the internet.</p>

            <p>Please don’t share personal info that you don’t want visible to the world, and never post social security numbers, passport details or similar info that could cause harm in the wrong hands. You may only post personal info relating to others where you have their consent and have kept a record of it.</p>

            <p>We don’t have to monitor the accuracy, reliability or legality of your content, but we may choose to do so. We may modify, remove or restrict access to content at any time in line with these Terms or apply a sensitive content warning to content that we deem unsuitable for all audiences.</p>

            <p>Our Platform</p>

            <p>We own the Platform, but give you a limited right to use it for sharing content and viewing and interacting with other users’ content. We’re not responsible for any content, products or services made available via other users’ profiles.</p>

            <p>All rights (including IP Rights) in and to the Platform (excluding your content) (the “Harmony IP”) are and will remain exclusively owned by Harmony or our licensors. You won’t acquire any rights in the Harmony IP and you’re not allowed to use it (including our brand name or logo) for any purpose (such as to imply a partnership with, or endorsement from Harmony), without our prior written approval.</p>

            <p>As a user, we grant you a limited, revocable, non-exclusive, non-transferable right to use the Platform to create, display, use, play, and upload content in accordance with these Terms. If we provide you with any images, icons, themes, fonts, videos, graphics, or other content, you must only use them on your profile and comply with any guidelines we make available to you.</p>

            <p>You must not remove, obscure or alter any proprietary notices or trade marks on the Platform, or make unauthorised copies of, reproduce, distribute, licence, sell, resell, modify, translate, disassemble, decompile, decrypt, reverse engineer, create any derivative works from, or attempt to derive the source code of, the Platform or any part of it. If you visit Harmony profiles (a “profile visitor”), we grant you a limited, non-exclusive, non-transferable right to view and interact with the Platform via user profiles.</p>

            <p>Third party links</p>

            <p>We’re not responsible for any third party links or content on Harmony. We may provide links to other websites, apps, resources, or services created by third parties (“Third Party Links”). When you click on these Third Party Links, you’re leaving the Platform. The Third Party Links are not under our control and we’re not responsible for the content of any Third Party Link or any link contained in a Third Party Link. We provide Third Party Links only as a convenience and the inclusion of a link does not imply endorsement, approval or recommendation by us of the site or its content.</p>

            <p>Contact and notices</p>

            <p class="mb-16">If you have any questions or need to contact us about these Terms, please get in touch with our team at support@harmony.fan</p>
        </v-col>
    </v-container>
</template>

<script>
    export default {
        name: "TermsConditions",
        metaInfo: {
            titleTemplate: '%s - Harmony Fan',
            title: 'Términos y condiciones'
        }
    };
</script>